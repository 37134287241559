exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alianza-ecologica-tsx": () => import("./../../../src/pages/alianza-ecologica.tsx" /* webpackChunkName: "component---src-pages-alianza-ecologica-tsx" */),
  "component---src-pages-certificado-financiero-tsx": () => import("./../../../src/pages/certificado-financiero.tsx" /* webpackChunkName: "component---src-pages-certificado-financiero-tsx" */),
  "component---src-pages-cuenta-de-ahorro-tsx": () => import("./../../../src/pages/cuenta-de-ahorro.tsx" /* webpackChunkName: "component---src-pages-cuenta-de-ahorro-tsx" */),
  "component---src-pages-cuenta-de-aportacion-tsx": () => import("./../../../src/pages/cuenta-de-aportación.tsx" /* webpackChunkName: "component---src-pages-cuenta-de-aportacion-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-prestamos-tsx": () => import("./../../../src/pages/prestamos.tsx" /* webpackChunkName: "component---src-pages-prestamos-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../../../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */)
}

